import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCarSide,
  faGlassCheers,
  faRingsWedding,
  faUtensilsAlt,
  faCocktail,
  faTurntable,
  faMicrophoneStand,
  faPeoplePulling,
  faTaxi,
} from "@fortawesome/pro-light-svg-icons"

function MoreDetails({ title, content }) {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "IMG_9421.jpeg" }) {
        childImageSharp {
          # Specify a fluid image and fragment
          # The default maxWidth is 800 pixels
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <div className="bg-black text-secondary text-xl">
      <div className="container-fluid flex flex-wrap justify-center items-stretch relative z-1">
        {data.file.childImageSharp.fluid && (
          <div className="w-full md:w-6/12 md:pr-5 md:pl-3 order-12 md:order-1">
            <Img
              fluid={data.file.childImageSharp.fluid}
              className="h-full w-full max-h-full max-w-full object-cover"
              alt="Holly & Olly"
              objectFit="cover"
              objectPosition="center center"
              imgStyle={{
                objectFit: "cover",
                objectPosition: "center center",
                backgroundPosition: "center center",
              }}
            />
          </div>
        )}
        <div className="w-full md:w-6/12 md:pr-3 md:pl-4 py-8 lg:py-20 order-1 md:order-12">
          <h2 className="text-4xl xs:text-5xl leading-tight md:text-6xl lg:text-8xl mb-2 md:mb-0 font-display">
            Order of the day
          </h2>
          <p className="text-xl">
            2:30pm - Arrival{" "}
            <FontAwesomeIcon
              className="text-2xl align-middle ml-1"
              icon={faCarSide}
            />
          </p>
          <p className="text-xl">
            3pm - Here comes the bride{" "}
            <FontAwesomeIcon
              className="text-2xl align-middle ml-1"
              icon={faRingsWedding}
            />
          </p>
          <p className="text-xl">
            <strong>
              The following schedule is not confirmed and may change. Please
              check back closer to the time for more details
            </strong>
          </p>
          <p className="text-xl">
            3:30pm – Drinks reception{" "}
            <FontAwesomeIcon
              className="text-2xl align-middle ml-1"
              icon={faGlassCheers}
            />
          </p>
          <p className="text-xl">
            4:00pm – Food trucks are open! Get your fill from 4pm - 10pm{" "}
            <FontAwesomeIcon
              className="text-2xl align-middle ml-1"
              icon={faUtensilsAlt}
            />
          </p>
          <p className="text-xl">
            5:00pm – Cock-o’clock (Cocktail hour){" "}
            <FontAwesomeIcon
              className="text-2xl align-middle ml-1"
              icon={faCocktail}
            />
          </p>
          <p className="text-xl">
            7:00pm - Speeches{" "}
            <FontAwesomeIcon
              className="text-2xl align-middle ml-1"
              icon={faMicrophoneStand}
            />
          </p>
          <p className="text-xl">
            7:30pm – First dance{" "}
            <FontAwesomeIcon
              className="text-2xl align-middle ml-1"
              icon={faPeoplePulling}
            />
          </p>
          <p className="text-xl">
            7:30pm - Lets party!
            <FontAwesomeIcon
              className="text-2xl align-middle ml-1"
              icon={faTurntable}
            />
          </p>
          <p className="text-xl">
            12:00am - Taxi! Home time{" "}
            <FontAwesomeIcon
              className="text-2xl align-middle ml-1"
              icon={faTaxi}
            />
          </p>
        </div>
      </div>
    </div>
  )
}
export default MoreDetails
