import React from "react"

class Intro extends React.Component {
  render() {
    return (
      <div className="bg-secondary text-black text-xl pt-8 pb-4 lg:py-20">
        <div className="container-fluid flex flex-wrap justify-center items-center">
          <div className="w-full sm:w-2/5 font-display sm:pr-2 md:pr-5 md:pl-3">
            <h1 className="flex flex-col text-4xl xs:text-5xl leading-1 md:text-6xl lg:text-8xl mb-2 sm:mb-0">
              <span className="flex-1 sm:block text-tertiary leading-0 md:mr-auto">
                {this.props.title_top}
              </span>
              <span className="flex-1 sm:block text-tertiary leading-0 md:ml-auto">
                {this.props.title_middle}
              </span>
              <span className="flex-1 sm:block text-tertiary leading-0 md:mr-auto">
                {this.props.title_bottom}
              </span>
            </h1>
          </div>
          <div
            className="sm:w-3/5 sm:pl-2 md:pr-5 md:pl-3"
            dangerouslySetInnerHTML={{ __html: this.props.content }}
          />
        </div>
      </div>
    )
  }
}
export default Intro
