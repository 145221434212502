import React from "react"
import SEO from "../components/SEO"
import Layout from "../components/Layout"
import Intro from "../components/Intro.js"

// import SplitModule from "../components/SplitModule"
import MoreDetails from "../components/MoreDetails"
import NextUp from "../components/NextUp"

class CeremonyPage extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="👰🤵 Ceremony" />
        <Intro
          title_top="The"
          title_middle="wedding day"
          title_bottom="vibe"
          content={`
          <p class="text-tertiary text-xl">We can’t wait to see you all!</p>
          <p class="text-tertiary text-xl">We want the day to be as fun and relaxed as possible so eat when you want, drink when you want and bring your best dance moves for the evening!</p>
          <p class="text-tertiary text-xl">There will be good food, great drinks and even better people.</p>
          `}
        />
        {/* <SplitModule
          title="Gifts"
          content={`
          <p className="text-xl">We will be flying back to Australia after the wedding, therefore our capacity to take gifts back with us will be minimal.</p>
          <p className="text-xl">Instead, we will be taking a piece of Anna’s Cyrpiot culture and doing the Greek Wedding Money Dance if you would like to contribute.</p>
          <p className="text-xl">No pressure here, your presence is our present.</p>
          `}
          gosshall={false}
          fireplace={true}
        /> */}
        {/* <Intro
          title_top="The"
          title_middle="Good Greek"
          title_bottom="Girl"
          content={`
          <p className="text-xl">Throughout the day we’ll be celebrating Anna’s Cypriot culture including traditional Greek dancing.</p>
          <p className="text-xl">Everyone will be called up to the dance floor to join in. These dances are very easy to master and are a lot of fun.</p>
          <p className="text-xl">So we recommend brushing up on your skills before the big day!</p>
          `}
          // content={`
          // <p className="text-xl">Throughout the day we’ll be celebrating Anna’s Cypriot culture including traditional Greek dancing.</p>
          // <p className="text-xl">Everyone will be called up to the dance floor to join in. These dances are very easy to master and are a lot of fun.</p>
          // <p className="text-xl">If you’d like to do some homework before coming then here’s a few links to brush up your skills.</p>
          // `}
        /> */}
        <MoreDetails />
        <NextUp
          locationOne={{
            label: "The Location",
            url: "/location",
          }}
          locationTwo={{
            label: "Let's RSVP",
            url: "/rsvp",
          }}
        />
      </Layout>
    )
  }
}
export default CeremonyPage
